import React from "react";

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import MediaPressItem from '../components/MediaPressItem/MediaPressItem';
import Footer from '../components/Footer/Footer';

import BgDesktopSrc from '../images/hero-main-bg-desktop.jpg';

const MediaPressItemPage = () => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
    >
      <Header />
      <Hero
        img={{ mobile: null, desktop: BgDesktopSrc }}
        title="Media&nbsp;&&nbsp;Press"
      />
      <MediaPressItem />
      <Footer />
    </div>
  );
};

export default MediaPressItemPage;
