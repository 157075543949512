import React from "react";

import { Root, Container, StampImg, Title, Body } from "./MediaPressItem.style";

import StampSrc from "../../images/stamp-beige.svg";

const MediaPressItem = () => {
  return (
    <Root>
      <Container>
        <StampImg src={StampSrc} alt="" />
        <Title>HEADLINE ONE</Title>

        <Body>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in
            purus vehicula, laoreet nunc quis, varius velit. Suspendisse viverra
            malesuada porta. Integer elementum orci nibh, non pharetra elit
            ultricies vitae. Etiam sodales et eros vitae sagittis. Aenean magna
            est, viverra sed rutrum vitae, tempus vitae felis. Ut tempus, est
            tempus iaculis sagittis, nunc nibh aliquam ipsum, vitae cursus nisi
            ex quis nisi. Ut euismod, libero ac aliquam luctus, lacus risus
            porttitor arcu, id gravida libero nunc quis sem. Orci varius natoque
            penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Proin magna odio, tincidunt eu nibh ac, fringilla sodales massa.
            Maecenas porttitor placerat ligula et posuere.
          </p>
          <p>
            Aenean scelerisque convallis ante sit amet aliquam. Vivamus nec
            fringilla nulla, ac mollis urna. Nulla ultrices augue lacus, in
            venenatis lacus sagittis sit amet. Orci varius natoque penatibus et
            magnis dis parturient montes, nascetur ridiculus mus. Pellentesque
            eleifend magna enim, non tristique tellus vehicula id. Sed sit amet
            rhoncus augue. Vestibulum tincidunt aliquam odio vel ultrices. Nunc
            scelerisque porta nibh, quis imperdiet velit rutrum ut. Nunc ac
            ornare magna. Aenean diam velit, aliquet eu laoreet vitae, auctor ac
            leo. Nullam leo nulla, elementum a luctus a, tincidunt at nulla.
            Duis ultrices finibus fringilla. Donec augue nibh, euismod at
            blandit quis, mollis eu odio. Nulla facilisi.
          </p>
        </Body>
      </Container>
    </Root>
  );
};

export default MediaPressItem;
